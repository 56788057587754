<template>
  <section class="hero-test">
    <div class="_background" v-if="featuredImage">
      <UiImage class="_image" v-bind="featuredImage" />
    </div>
    <div class="_body">
      <h2 ref="elTitle">{{ title }}</h2>
    </div>
  </section>
</template>

<script setup lang="ts">
import './heroTest.css'

import UiImage from '~/components/ui/image/image.vue'

import type { THeroTest } from './heroTest.type.ts'

const props = withDefaults(defineProps<THeroTest>(), {})

const elTitle = ref()

// Animation
useGSAP(() => {})
</script>
